import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip, Whisper } from 'rsuite';
import {
    getTipoPromocaoList,
    setConsolidadoData,
    setEnderecoRaio,
    setGenerateReportData
} from '../../../../../../actions/actionsModuloRelatorio';
import {
    changeCache,
    changeValue,
    getItensFiltro,
} from '../../../../../../actions/actionsPainelGeral';
import DndColumn from './DndColumn';
import DndMovableItem from './DndMovableItem';
import { DndSelectRaio } from './DndSelectRaio';

import { getModelPanel } from '../../../InfoPanel/utils';
import { filtrosDataType, flagData } from '../../utils';

import chevronLeftIcon from '../../../../../../assets/icons/icon_chevron-double-left.svg';
import chevronRightIcon from '../../../../../../assets/icons/icon_chevron-double-right.svg';

const DndComponent = ({
    dndItems,
    columnTitles,
    elementLeft,
    elementRight,
    dndName,
    functions,
    products,
    enderecoHandlers,
}) => {
    const [items, setItems] = useState(dndItems);
    const [timer, setTimer] = useState(null);

    const dispatch = useDispatch();

    const {
        filtrosData,
        moduloRelatorioData,
        showModalConfirmDownload,
        tipoPromocaoSelectData,
        reportSaveSuccess,
        clienteData,
        endereco,
    } = useSelector(
        (state) => ({
            filtrosData: state.painelGeralDataReducer.filtrosData,
            moduloRelatorioData:
                state.moduloRelatorioDataReducer?.moduloRelatorioData,
            showModalConfirmDownload:
                state.moduloRelatorioDataReducer?.showModalConfirmDownload,
            tipoPromocaoSelectData:
                state.moduloRelatorioDataReducer?.tipoPromocaoSelectData,
            reportSaveSuccess:
                state.moduloRelatorioDataReducer?.reportSaveSuccess,
            endereco: state.moduloRelatorioDataReducer?.endereco,
            clienteData: state.clienteDataReducer?.clienteData,
        }),
        shallowEqual,
    );

    const handleOnOpen = (listName) => {
        const validateName = listName !== 'Endereço' &&
            listName !== 'Flag promoção' &&
            listName !== 'Tipo promoção' &&
            listName !== 'Produto'

        const service = {
            Endereço: () => {},
            'Flag promoção': () => {},
            'Tipo promoção': () => dispatch(getTipoPromocaoList()),
            Produto: () => functions?.onSearchProducts(),
            default: () =>
                dispatch(
                    getItensFiltro(
                        getModelPanel(
                            filtrosData,
                            '',
                            filtrosDataType[listName],
                        ),
                        filtrosDataType[listName],
                    ),
                ),
        };

        return validateName ? service.default() : service[listName]()
    };

    const handleAddItem = (name, value, item) => {
        const addValue =
            moduloRelatorioData[filtrosDataType[name]].concat(value);
        const addItem = filtrosData[filtrosDataType[name]]?.cache.concat(item);

        dispatch(setGenerateReportData(filtrosDataType[name], addValue));

        if (name !== 'Tipo promoção' && name !== 'Flag promoção') {
            dispatch(changeValue(addValue, filtrosDataType[name]));
            dispatch(changeCache(filtrosDataType[name], addItem));
        }
    };

    const handleRemoveItem = (name, value) => {
        const removeItem = moduloRelatorioData[filtrosDataType[name]].filter(
            (i) => i !== value,
        );

        dispatch(setGenerateReportData(filtrosDataType[name], removeItem));

        if (name !== 'Tipo promoção' && name !== 'Flag promoção') {
            const removeFiltrosDataCache = filtrosData[
                filtrosDataType[name]
            ].cache.filter((item) => item.value !== value);

            dispatch(changeValue(removeItem, filtrosDataType[name]));
            dispatch(
                changeCache(filtrosDataType[name], removeFiltrosDataCache),
            );
        }
    };

    const handleSelect = (item, name) => {
        if (name === 'Endereço') {
            enderecoHandlers.handleSelectEndereco(item);

            return;
        }

        if (name === 'Produto') {
            if (products?.value.includes(item.identificador)) {
                functions?.handleRemoveProduct(item);
                handleRemoveItem(name, item.identificador);
            } else {
                functions?.handleAddProduct(item);
                handleAddItem(name, item.identificador);
            }
        }

        if (moduloRelatorioData[filtrosDataType[name]].includes(item.value)) {
            handleRemoveItem(name, item.value);
        } else {
            handleAddItem(name, item.value, item);
        }
    };

    const handleClean = (name) => {
        dispatch(setGenerateReportData(filtrosDataType[name], []));
        dispatch(changeCache(filtrosDataType[name], []));
        dispatch(changeValue([], filtrosDataType[name]));

        if (name === 'Produto') {
            functions?.handleCleanProduct();
        }

        if (name === 'Endereço') {
            enderecoHandlers.handleCleanEndereco();
        }
    };

    const defaultSearch = (inputValue, name) => {
        clearTimeout(timer);

        const newTimer = setTimeout(() => {
            dispatch(
                getItensFiltro(
                    getModelPanel(
                        filtrosData,
                        inputValue,
                        filtrosDataType[name],
                    ),
                    filtrosDataType[name],
                ),
            );
        }, 500);

        setTimer(newTimer);
    };

    const handleSearch = (inputValue, name) => {
        switch (name) {
            case 'Endereço':
                enderecoHandlers?.handleSearchEndereco(inputValue);
                break;
            case 'Produto':
                functions?.onSearchProducts(inputValue);
                break;
            default:
                defaultSearch(inputValue, name);
                break;
        }
    };

    const setSelectDataFilter = (name) => {
        const data = {
            Endereço: endereco?.lista,
            'Flag promoção': flagData,
            'Tipo promoção': tipoPromocaoSelectData,
            Produto: products?.list,
            default: filtrosData[filtrosDataType[name]]?.lista,
        };
        return data[name] || data.default;
    };

    const disableMovableElement = (name) => {
        const hasShoppingBrasilPermission =
            clienteData?.tipoAdicionalContratos?.PAN?.includes('SB');
        
        if (name === 'Detalhes anúncio') {
            return hasShoppingBrasilPermission;
        }
        
        return true;
    };

    const setDndItemsValue = (name) => {
        const valueType = {
            Endereço: moduloRelatorioData.enderecoDTO?.endereco,
            Produto: products?.value,
            default: moduloRelatorioData[filtrosDataType[name]],
        };
        return valueType[name] || valueType.default;
    };

    const renderSelectValue = (type, name) => {
        if (type === 'select') {
            return <span>{name}</span>;
        }
        return null;
    };

    const returnItemsForColumn = (columnName) =>
        items
            .filter((item) => item.column === columnName)
            .map((item) => (
                <DndMovableItem
                    key={item.id}
                    type={item.selectType}
                    name={item.name}
                    setItems={setItems}
                    hasList={item.hasList}
                    column={item.column}
                    canDrag={disableMovableElement(item.name)}
                    disabled={!disableMovableElement(item.name)}
                    value={setDndItemsValue(item.name)}
                    locale={{
                        noResultsText: 'Nenhum item encontrado',
                        searchPlaceholder: item.search,
                    }}
                    data={setSelectDataFilter(item.name)}
                    onOpen={() => handleOnOpen(item.name)}
                    onSelect={(v, itemSelected) => {
                        handleSelect(itemSelected, item.name);
                    }}
                    onSearch={(search) => handleSearch(search, item.name)}
                    onClean={() => handleClean(item.name)}
                    resetProduct={functions?.handleCleanProduct}
                    renderValue={() =>
                        renderSelectValue(item.selectType, item.name)
                    }
                />
            ));

    const handleMoveAllElements = (column) => {
        const hasShoppingBrasilPermission =
            clienteData?.tipoAdicionalContratos?.PAN?.includes('SB');
        const hasAD = items.some((i) => i.name === 'Detalhes anúncio');

        if (hasAD && !hasShoppingBrasilPermission) {
            setItems((prevState) =>
                prevState.map((e) => {
                    if (e.name !== 'Detalhes anúncio') {
                        return {
                            ...e,
                            column,
                        };
                    }
                    return e;
                }),
            );
        } else {
            setItems((prevState) =>
                prevState.map((e) => ({
                    ...e,
                    column,
                })),
            );
        }
    };

    useEffect(() => {
        if (returnItemsForColumn('2').length) {
            dispatch(setConsolidadoData(dndName, items));
        } else {
            dispatch(setConsolidadoData(dndName, []));

            items.map((i) => {
                if (
                    i.column === '1' &&
                    moduloRelatorioData[filtrosDataType[i.name]]?.length
                ) {
                    handleClean(i.name);
                    if (i.name === 'Endereço') {
                        dispatch(setEnderecoRaio(null));
                    }
                }
            });
        }
    }, [items]);

    useEffect(() => {
        if (showModalConfirmDownload === false) {
            handleMoveAllElements('1');
            handleClean('Produto');
        }
    }, [showModalConfirmDownload]);

    useEffect(() => {
        setItems(dndItems);
    }, [dndItems]);

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="dnd-columns-wrapper">
                <DndColumn
                    name="1"
                    title={columnTitles?.length ? columnTitles[0] : null}
                    className="column first-column"
                    element={elementLeft}
                >
                    {returnItemsForColumn('1')}
                </DndColumn>
                <div className="dnd-buttons-wrapper">
                    <Whisper
                        placement="top"
                        speaker={<Tooltip>Selecionar todos</Tooltip>}
                    >
                        <Button
                            className="button-send-right"
                            onClick={() => handleMoveAllElements('2')}
                        >
                            <img src={chevronRightIcon} alt="" />
                        </Button>
                    </Whisper>
                    <Whisper
                        placement="bottom"
                        speaker={<Tooltip>Limpar todos</Tooltip>}
                    >
                        <Button
                            className="button-send-left"
                            onClick={() => handleMoveAllElements('1')}
                        >
                            <img src={chevronLeftIcon} alt="" />
                        </Button>
                    </Whisper>
                </div>
                <DndColumn
                    name="2"
                    title={columnTitles?.length ? columnTitles[1] : null}
                    className="column second-column"
                    element={elementRight}
                >
                    {returnItemsForColumn('2')}
                    {returnItemsForColumn('2').some(
                        (item) => item.props.name === 'Endereço',
                    ) ? (
                        <DndSelectRaio />
                    ) : null}
                </DndColumn>
            </div>
        </DndProvider>
    );
};

export default DndComponent;
